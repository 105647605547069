<template>
  <svg
    width="22"
    height="10"
    viewBox="0 0 22 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.375 3.125H1.625C1.00391 3.125 0.5 2.62344 0.5 2C0.5 1.37656 1.00391 0.875 1.625 0.875H20.375C20.9984 0.875 21.5 1.37656 21.5 2C21.5 2.62344 20.9984 3.125 20.375 3.125ZM20.375 9.125H1.625C1.00391 9.125 0.5 8.62344 0.5 8C0.5 7.37656 1.00391 6.875 1.625 6.875H20.375C20.9984 6.875 21.5 7.37656 21.5 8C21.5 8.62344 20.9984 9.125 20.375 9.125Z"
      fill="#191617"
    />
  </svg>
</template>
